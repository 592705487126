@import "./../../Assets/style/main.scss";

.custom-pagination {
  .pagination > .active > a,
  .pagination > .active > a:focus,
  .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    background-color: $SecondryColor;
    border-color: $SecondryColor;
  }

  .pagination > li > a,
  .pagination > li > span {
    color: $SecondryColor;
    padding: 0.5rem 0.75rem !important;
    cursor: pointer;
  }

  .pagination > li > a,
  .pagination > li > span {
    padding: 0.5rem !important;
  }
  .paginate_button .previous .page-link,
  .paginate_button .next .page-link {
    border: solid 1px $SecondryColor !important;
    color: $SecondryColor !important;
    background-color: $white !important;
  }

  .paginate_button .previous .page-link,
  .paginate_button .next .page-link,
  .page-item .page-link {
    font-size: 12px !important;
    height: 21px !important;
    line-height: 1 !important;
    padding-top: 4px !important;
    border: solid 1px $SecondryColor !important;
  }

  .paginate_button .previous .page-link .fa-caret-left,
  .paginate_button .next .page-link .fa-caret-right {
    line-height: 0 !important;
  }

  .paging_simple_numbers {
    background: $white;
  }

  .dataTables_info {
    font-size: 12px;
    color: $TextColor;
    padding-top: 2px;
  }
}
