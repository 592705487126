.collector_profile_wrapper_style,
.donor_profile_wrapper_style {
  .edit_btn_style,
  .settle_balance_btn_style,
  .add_balance_btn_style {
    height: 28px;
    text-transform: capitalize;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.44px;
    color: $white;
  }

  .edit_btn_style {
    padding: 0 16px;
    background-color: $PlaceholderColor;
  }

  .settle_balance_btn_style {
    padding: 0 22px;
    background-color: $olive-green;
  }

  .add_balance_btn_style {
    padding: 0 22px;
    background-color: $SecondryColor;
  }

  h1 {
    font-size: 22px;
    font-weight: bold;
    color: $blue-navy;
  }

  h2 {
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 500;
    color: $TextColor;
  }

  p {
    font-size: 15px;
    margin: 2px 0px;
    color: $TextColor;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid rgba(167, 167, 167, 0.51);
  }

  .table-bordered {
    thead {
      th,
      td {
        border-bottom-width: 1px;
        border-bottom: 1px solid rgba(167, 167, 167, 0.51);
      }
    }

    th,
    td {
      font-size: 13px;
      padding: 8px 15px;
      text-align: center;
      border: 1px solid rgba(167, 167, 167, 0.51);
    }

    th {
      font-weight: 300;
      color: $SecondryColor;
    }

    td {
      font-weight: normal;
      color: #242424;
    }
  }

  h3 {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    color: $blue-navy;
  }

  .collection_history_wrapper_style {
    padding-top: 3.5px;
  }
}

@media only screen and (max-width: 767px) {
  .collector_profile_wrapper_style,
  .donor_profile_wrapper_style {
    .collection_history_wrapper_style {
      padding-top: 4.5px;
      padding-left: 18px;
    }

    label {
      font-size: 15px;
    }

    h1 {
      font-size: 18px;
    }
  }
}

.settle_balance_wrapper_style,
.edit-collection {
  h1,
  p {
    text-transform: capitalize;
    font-size: 10.5px;
    font-weight: bold;
  }

  h1 {
    color: $mid-black;
  }

  p {
    line-height: 1.1;
    color: $PlaceholderColor;
  }

  .balance_wrapper_style {
    padding-top: 33px;
  }

  .btns_wrapper_style {
    padding-top: 25px;
    padding-left: 18px;

    .sucsses_btn_style,
    .delete_btn_style {
      height: 25px;

      .MuiSvgIcon-root {
        width: 16px;
        height: 23px;
      }
    }
  }
}

.collector_datatable_wrapper_style {
  .rdt_TableHeadRow {
    &:first-child {
      .bmkkvw {
        flex: 0 1 !important;
        padding-left: 55px !important;
      }
    }
  }

  .rdt_TableCol {
    #column-amount {
      transform: translateX(-12px);
    }
  }

  .withdraw_amount_status_style {
    color: #ff6a6a;
  }

  .deposit_amount_status_style {
    color: $dark-green;
  }
}
