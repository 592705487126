.donor_profile_wrapper_style {
  .address_title_style,
  .address_info_style {
    font-size: 13px;
    color: $PlaceholderColor;
    margin-right: 14px;
  }

  .address_title_style {
    font-weight: bold;
    white-space: nowrap;
  }

  label {
    font-size: 17px;
    color: $blue-navy;
  }
}
