$BodyBg: #f7f9fb;
$PrimaryColor: #d2b486;
$SecondryColor: #6baddf;
$white: #ffffff;
$BorderColor: #eaeaea;
$lightGrey: #e6e8f1;
$NavbarItemsColor: #a7a7a7;
$blue-navy: #01193f;
$PlaceholderColor: #75777a;
$lightNavyBlue: #73a1c2;
$TextColor: #576271;
$olive-green: #35a992;
$dark-green: #249a84;
$MidGreyColor: #d9dce6;
$grayish-black: #242424;
$mid-black: #131313;
$dark-red: #ee0404;