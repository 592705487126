.search-time-range {
  .form-check-label {
    font-size: 14px !important;
    font-weight: normal;
    color:#242424    ;
    font-family: Roboto;
  }
  .radio > span {
    top: 4px;
    height: 14px !important;
    width: 14px !important;
    border: 1px solid #75777a;
  }
  .radio > span:after {
    top: 50%;
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    height: 8px;
    width: 8px;
  }
  .active-radio-filter{
      font-weight: 600;
  }
}
