.general-data-list {
  box-shadow: 0 3px 6px 0 #e9e8e8;
  background-color: $white;
  border-radius: 2px !important;

  .rdt_TableHeadRow,
  .jbXzPk {
    background-color: $white;
    min-height: 40px;
    border-bottom-width: 0px;
  }

  .rdt_TableCol {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33;
    color: $TextColor;
    text-transform: capitalize;
    white-space: nowrap;
    border: none !important;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    vertical-align: middle;
    font-family: $RobotoFont !important;
    padding: 10px 23px 7px 23px;
    border-bottom: solid 1px #e0e3da !important;

    &:first-child {
      margin-left: 23px;
      padding-left: 0;
      padding-bottom: 0;

      .rdt_TableCol_Sortable {
        border-bottom: 1px solid $SecondryColor;
        height: 28px;
        padding-bottom: 6px;
      }
    }

    &:last-child {
      margin-right: 23px;
      padding-right: 0;
    }
  }

  .rdt_TableBody {
    margin: 0 23px;
  }

  .rdt_TableRow {
    background-color: $white;
    border-bottom-color: $BodyBg !important;
  }

  .rdt_TableCell {
    padding: 0px 23px;
    vertical-align: middle !important;
    font-size: 13px;
    font-weight: normal !important;
    line-height: 1.38;
    color: $TextColor;
    border-top: none !important;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 11px;
      padding-left: 10px;
    }
  }

  a {
    text-decoration: none !important;
    color: $SecondryColor;
  }

  .general-data-table-action {
    .fa-pencil-alt {
      color: $SecondryColor;
    }

    .fa-times,
    .fa-trash {
      color: #ff6a6a;
    }
  }

  .inITFc {
    min-height: 39px !important;
  }

  .Collected_status_style,
  .Pending_status_style,
  .Cancelled_status_style,
  .Scheduled_status_style {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11.5px 0px;
    height: 100%;
    width: 100%;

    .btn {
      padding: 2px 0px 0px 2px;
    }

    .fa-exclamation-circle {
      width: 9px;
    }
  }

  .Collected_status_style {
    color: $SecondryColor;
    background-color: #dfedf8;
  }

  .Pending_status_style {
    color: $PrimaryColor;
    background-color: #f4ece1;
  }

  .Cancelled_status_style {
    color: $dark-red;
    background-color: #fccdcd;
    padding: 8.5px 0px 6px 3px;

    .fa-exclamation-circle {
      color: $dark-red;
    }
  }

  .Scheduled_status_style {
    color: $olive-green;
    background-color: #cdeae4;
    padding: 8.5px 0px 6px 2px;

    .fa-exclamation-circle {
      color: $olive-green;
    }
  }
}

.donor_pickup_list_wrapper_style {
  .rdt_TableCell {
    &:last-child {
      padding: 0px 0px 0px 45px;
    }
  }

  .rdt_TableCol {
    &:last-child {
      padding-right: 0;
      padding-left: 47px;
    }
  }
}

.pickup_data_list_wrapper_style {
  .rdt_TableCell {
    &:nth-child(6),
    &:nth-child(7) {
      justify-content: center;
    }
  }

  .rdt_TableCol {
    &:nth-child(6),
    &:nth-child(7) {
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .general-data-list {
    .Collected_status_style,
    .Pending_status_style,
    .Cancelled_status_style,
    .Scheduled_status_style {
      font-size: 8.5px;
    }
  }
}

@media only screen and (min-width: 1590px) {
  .general-data-list {
    .Cancelled_status_style,
    .Scheduled_status_style {
      padding: 8.5px 10px 6px 18px;
    }
  }
}
