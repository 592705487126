@import "../../Assets/style/colors";

.date-picker-input-default {
  height: 25px;
  border: 1px solid $BorderColor !important;
  padding: 3.5px 20px;
  color: $PlaceholderColor;
  font-size: 13px;
  width: 100%;

  &::placeholder {
    color: $PlaceholderColor;
  }

  .react-datepicker__month-container {
    width: 75%;
    overflow-x: scroll;
  }
  .react-datepicker__time-container {
    width: 25%;
    overflow: scroll;
  }

  .react-datepicker__month {
    margin: 0;
  }
}

.w-100 {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.container-style-default {
  .date-picker-input-default {
    min-width: 100%;
  }
}

.react-datepicker__triangle {
  left: 74px !important;
}

.react-datepicker__close-icon {
  &:after {
    background-color: transparent;
    color: $dark-red;
    height: 19px;
    width: 16px;
    padding: 0px;
    font-size: 19px;
  }
}
