@import "../../Assets/style/colors";

.buttonRoot {
  width: 25%;
  background-color: transparent !important;
  &:focus {
    outline: 0;
  }
  &:hover .iconRoot {
    // color: $mainColor;
  }
}

.stepInputRoot {
  width: 50%;
  height: 22px;
  border: 0;
  text-align: center;
  //   color: $darkColor;
  font-size: 15px;
}

.suffix-input {
  width: 50%;
  padding: 0 10px;
  text-align: center;
  border: 0;
}

.suffix-style-default {
  //   border-left: solid 1px $lightGray;
  background-color: #f5f5f5;
  //   color: $secGray;
  text-align: center;
  font-size: 14px;
  width: 50%;
  height: 100%;
  line-height: 1.9;
}

.iconRoot {
  //   color: $mainGray;
  //   border: 1px solid $lightGray;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 16px !important;
  font-weight: bold;
}

.input-style-default,
.input-container-style-default {
  display: flex;
  height: 25px;
  border-radius: 2px;
  background-color: $white;
}

.input-container-style-default {
  align-items: center;
  margin-left: auto;
}

.label-style-default {
  font-family: "Roboto", sans-serif;
}

.input-style-default,
textarea {
  border: 1px solid $BorderColor !important;
  padding: 3.5px 20px;
  min-width: 100%;
  color: $PlaceholderColor;
  font-size: 13px;

  &::placeholder {
    color: $PlaceholderColor;
  }
}

textarea {
  border: 1px solid $BorderColor !important;
  border-radius: 2px;
  height: 104px;
}

.lightActiveColor {
  color: $SecondryColor;
  font-weight: bold;
  font-size: 12px;
}