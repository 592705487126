.login-body-wrapper {
  background-color: $BodyBg;

  .input-style-default,
  .input-container-style-default,
  .login_btn_style {
    height: 42px;
  }

  .input-style-default {
    font-family: $MontserratFont !important;
    border: transparent !important;
    color: $lightNavyBlue;

    &::placeholder {
      color: $lightNavyBlue;
    }
  }

  .forgot_pass_style,
  .login_btn_style {
    font-family: $MontserratFont;
    font-size: 13px;
  }

  .forgot_pass_style {
    font-weight: 500;
    color: $blue-navy !important;
    &:hover {
      text-decoration: none !important;
    }
  }

  .login_btn_style {
    font-weight: 600;
    text-transform: uppercase;
    color: #e8eff7;
    background-color: $blue-navy;
  }

  .input-container-style-default {
    div {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .w-md-75 {
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) {
  .w-lg-25 {
    width: 25%;
  }
}
