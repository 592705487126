.custom_timeline_wrapper_style {
  padding: 0 !important;
  align-items: flex-start;
  margin-bottom: 0px;

  .MuiTimelineDot-root {
    box-shadow: none;
    padding: 10px;
    margin-top: 0;
    border-width: 3px;
    margin-bottom: 0px;
  }

  .small_circle_style {
    padding: 0.5px;
    color: $MidGreyColor;
    background-color: $MidGreyColor;
    box-shadow: none;
    position: absolute;
    margin-left: 9.5px;
    margin-top: 37px;
  }

  .MuiTimelineConnector-root {
    width: 1px;
    background-color: $MidGreyColor !important;
  }

  .MuiTimelineItem-root {
    width: 100%;

    &:last-child {
      .small_circle_style,
      .MuiTimelineConnector-root {
        display: none;
      }
    }
  }

  .MuiTimelineContent-root {
    flex: 5;
    padding: 6px 0px 6px 18px;
  }

  .MuiTimelineOppositeContent-root {
    padding: 6px 18px 0px 0px;
  }

  h1,
  h2,
  h3 {
    font-size: 12px;
  }

  h1,
  h2 {
    text-transform: uppercase;
  }

  h1 {
    color: #686868;
  }

  h2 {
    letter-spacing: 1.2px;
    color: #a1a1a1;
  }

  h3 {
    color: $grayish-black;
  }

  .Collected_status_style {
    border-color: $SecondryColor;
  }

  .Rescheduled_status_style {
    border-color: $PlaceholderColor;
  }

  .Cancelled_status_style {
    border-color: $dark-red;
  }

  .Created_status_style {
    border-color: $PrimaryColor;
  }

  .Scheduled_status_style {
    border-color: $olive-green;
  }
}

.add_donor_pickup_modal_wrapper_style {
  .donor_info_in_modal_style {
    padding-top: 10px;

    h1,
    p {
      text-transform: capitalize;
      font-size: 11px;
    }

    h1 {
      font-weight: bold;
      color: $TextColor;
    }

    p {
      color: $mid-black;
      line-height: 1.35;
    }
  }
}

.add_pickup_addresses_modal_wrapper_style {
  label {
    font-size: 13px;
    align-items: center;
    display: flex;
    font-weight: bold;
    color: $blue-navy;
  }
}

.pickupaddress_input_wrapper_style {
  .delete_btn_style,
  .sucsses_btn_style {
    height: 25px;
    padding: 0px 6px;

    .MuiSvgIcon-root {
      width: 13px;
      height: 13px;
    }
  }
}