.donor_pickup_adresses_wrapper_style {
  label {
    font-size: 14px;
    font-weight: bold;
    line-height: 2.08;
    color: $blue-navy;
    text-transform: capitalize;
  }
}

.addresses_wrapper_style {
  padding-left: 22px;

  &:last-child {
    hr {
      display: none;
    }
  }

  .MuiTypography-root,
  .MuiFormControlLabel-root {
    width: 100% !important;
  }

  .MuiFormControlLabel-root {
    margin-left: -15px;
  }

  .PrivateRadioButtonIcon-root-5 {
    .MuiSvgIcon-root {
      fill: $PlaceholderColor;
      width: 15px;
      height: 15px;
    }
  }

  .PrivateSwitchBase-root-1 {
    padding: 10px 7.5px;
  }

  .PrivateRadioButtonIcon-root-5.PrivateRadioButtonIcon-checked-7 {
    .MuiSvgIcon-root {
      fill: $SecondryColor;
    }
  }

  .MuiIconButton-colorPrimary,
  .MuiRadio-colorPrimary.Mui-checked {
    &:hover {
      background-color: transparent;
    }
  }
}
