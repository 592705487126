/*--------------------------------------- Start of General styles ---------------------------------------*/
html {
  height: 100%;
}

body {
  font-family: $RobotoFont !important;
  background-color: $BodyBg !important;
  overflow-x: hidden;
  height: 100%;
}

.content {
  padding: 20px 36px;
}

input,
button,
textarea {
  outline: none;
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

label {
  font-size: 12.5px;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 4px;
  color: $mid-black;
}

.form-group {
  margin-bottom: 0;
}

// radio btns & checkboxses styles starts here
label.radio,
.checkbox {
  font-size: 10.5px !important;
  font-weight: bold !important;
  color: $PlaceholderColor !important;
  display: inline-block !important;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  line-height: 1.6;
}

.radio > input,
.checkbox > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.radio > span,
.checkbox > span {
  background-color: #e2edf6;
  position: absolute;
  top: 2.5px;
  left: 0;
  height: 12px !important;
  width: 12px !important;
  border: 1px solid #e2edf6;
}

.radio > span {
  border-radius: 50% !important;
}

.checkbox > span {
  border-radius: 2px !important;
}

.radio > input:checked ~ span,
.checkbox > input:checked ~ span {
  background-color: $SecondryColor;
  border-color: $SecondryColor;
}

.radio > input:checked ~ span:after,
.checkbox > input:checked ~ span:after {
  display: block;
}

.radio > span:after,
.checkbox > span:after {
  content: "";
  position: absolute;
  display: none;
}

.radio > span:after {
  top: 50%;
  left: 50%;
  margin-left: -3px;
  margin-top: -3px;
  height: 6px;
  width: 6px;
  border-radius: 100% !important;
  background-color: $SecondryColor !important;
}

.checkbox > span:after {
  border: solid $white;
  top: 50%;
  left: 50%;
  margin-left: -2px;
  margin-top: -5px;
  width: 5px;
  height: 8px;
  border-width: 0 2px 2px 0 !important;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
// radio btns & checkboxses styles ends here

// custom-nav-tabs style starts here
.custom_nav_tabs_wrapper_style {
  .nav-link {
    padding: 4px 25px 2px;
    font-size: 14px;
    font-weight: 500;
    color: #b3b8bd;
    cursor: pointer;
    text-transform: capitalize;

    &:hover,
    &:focus {
      border-color: transparent !important;
    }
  }

  .nav-tabs {
    border-bottom: none;
    .nav-item.show {
      .nav-link {
        color: $white;
        border-color: $blue-navy;
        border-radius: 2px;
        background-color: $blue-navy;
      }
    }

    .nav-link.active {
      color: $white;
      border-color: $blue-navy;
      border-radius: 2px;
      background-color: $blue-navy;
    }
  }
}
// custom-nav-tabs style ends here

// react-select style starts here
.select-def-input-container,
.b-select-style--is-disabled.css-14jk2my-container {
  .css-yk16xz-control,
  .css-1pahdxg-control {
    background-color: $white;
    border-color: $BorderColor;
    border-radius: 2px;
    height: 25px;
    min-height: 25px;
    line-height: 0.8 !important;
    &:hover {
      border-color: $BorderColor;
    }
  }

  .b-select-style__control--is-disabled.css-1fhf3k1-control {
    border-color: $BorderColor;
    border-radius: 2px;
    height: 25px;
    min-height: 25px;
    line-height: 0.8 !important;
    &:hover {
      border-color: $BorderColor;
    }
  }

  .css-b8ldur-Input {
    margin: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    color: $PlaceholderColor;
  }

  .b-select-style__value-container {
    height: 25px;
    min-height: 25px;
    line-height: 0.8 !important;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 0px 5px;
    width: 25px;
  }

  .css-1wa3eu0-placeholder,
  .css-1uccc91-singleValue,
  .b-select-style__single-value--is-disabled.css-107lb6w-singleValue {
    font-size: 13px;
    color: $PlaceholderColor;
    margin-left: 11px;
  }

  .css-yt9ioa-option,
  .css-1n7v3ny-option,
  .css-9gakcf-option {
    color: $blue-navy !important;
    font-size: 13px !important;
  }

  .css-1n7v3ny-option,
  .css-9gakcf-option,
  .css-1rhbuit-multiValue {
    background-color: rgba(107, 173, 223, 0.1);
  }

  .css-1rhbuit-multiValue {
    border-radius: 0px;
    margin: 0px 2px 2px;
  }

  .css-12jo7m5,
  .css-xb97g8 {
    border-radius: 0px;
    color: $blue-navy;
    font-size: 10px;
  }

  .css-12jo7m5 {
    padding: 2px;
    padding-left: 7px;
  }
}
// react-select style ends here

.search_btn_style,
.add_new_btn_style,
.delete_btn_style,
.sucsses_btn_style {
  color: $white !important;
  height: 28px;
  padding: 0px 7px;
  font-size: 12px;
  border-radius: 4px;
  .MuiSvgIcon-root {
    width: 1.2rem;
    height: 26px;
  }
}

.search_btn_style {
  border: solid 1px rgba(210, 180, 134, 0.42);
  background-color: rgba(210, 180, 134, 0.59);
}

.delete_btn_style {
  background-color: $dark-red;
}

.sucsses_btn_style {
  background-color: $dark-green;
}

.add_new_btn_style {
  display: flex;
  border: 1px solid $SecondryColor;
  background-color: $SecondryColor;

  p {
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 5px;
    padding: 0 7px;
  }
}

// error message style starts here
.warnig-msg-style {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: $dark-red !important;
}

.validity-msg-style {
  display: none;
}

.invalid-container-style {
  .validity-label-style,
  .validity-input-style,
  .validity-msg-style {
    color: $dark-red;
  }

  .validity-input-style,
  .b-select-style__control,
  .custom_select_style__control {
    border-color: $dark-red !important;
  }

  .validity-msg-style {
    display: block;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
// error message style ends here

// custom tooltip styles starts here
.custom_tooltip_wrapper_style {
  .MuiTooltip-tooltip {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.42);
    background-color: $white;
    font-size: 10px;
    line-height: 1.75;
    padding: 8px 12px;
    color: $TextColor;
  }

  .MuiTooltip-arrow {
    color: $white;
  }
}
// custom tooltip styles ends here

/*--------------------------------------- Start of Navbar style ---------------------------------------*/
.navbar {
  height: 53px;
  background-color: $white;
  border-bottom: 1px solid $lightGrey;
  padding: 0.5rem 36px;

  .rounded_circle_user_img {
    width: 36px;
    height: 36px;
    font-size: 17px;
    line-height: 2;
    color: $white;
    background-color: $PrimaryColor;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-weight: 700;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .dropdown {
    border-left: 1px solid $lightGrey;
    padding-left: 40px;

    .dropdown-menu {
      padding: 4px 0;
    }

    .dropdown-item {
      font-size: 14px;
      color: $NavbarItemsColor;

      &:hover,
      &:focus {
        color: $PrimaryColor;
        background-color: rgba(210, 180, 134, 0.3);
      }
    }
  }

  .navbar-brand {
    margin-right: 6rem;
  }

  .logo_wrapper_style {
    img {
      height: 40px;
    }
  }

  .nav-item {
    .nav-link {
      text-transform: capitalize;
      cursor: pointer;
      padding: 22px 35px 15px 35px;
      font-size: 14px;
      line-height: 1;
      color: $NavbarItemsColor;
    }

    &:hover,
    &:focus {
      .nav-link {
        color: $PrimaryColor;
      }
    }

    .active {
      color: $PrimaryColor;
      background-color: rgba(210, 180, 134, 0.25);
      border-bottom: solid 2px $PrimaryColor;
    }
  }

  .navbar-toggler {
    padding: 2px 0px 3px 8px;
    font-size: 18px;
    border-radius: 4px;
  }

  .navbar-toggler-icon {
    background-image: url(../img/menu-bar.svg);
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    background: $white;
    z-index: 1050;
    box-shadow: 0 3px 6px 0 rgba(77, 77, 77, 0.37);
    .navbar-nav {
      padding-bottom: 12px;
      border: 1px solid $PrimaryColor;
      border-radius: 4px;
    }

    .nav-item {
      .nav-link {
        padding: 18px 35px 14px 20px;
      }
    }

    .dropdown {
      border-left: none;
      padding-left: 19px;
      padding-top: 9px;

      .dropdown-menu {
        width: 70%;
      }

      a {
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: -ms-fit-content;
      }
    }
  }
}
/*------------------------------------------------- End of Navbar style ---------------------------------------*/

/*------------------------------------------------ Start of Modal style ------------------------------------------*/
.modal {
  .close {
    font-size: 21px;
    color: $blue-navy;
  }

  .modal-content {
    border: none !important;
    border-radius: 2px !important;
    box-shadow: 0 3px 6px 0 rgba(77, 77, 77, 0.37);

    hr {
      border-top: 1px solid rgba(167, 167, 167, 0.51);
    }

    .modal-header {
      padding: 0.5rem 1.5rem !important;
      background-color: $BodyBg;
      border-bottom: none !important;

      .modal-title {
        font-size: 14px;
        text-transform: capitalize;
        font-weight: bold;
        color: $blue-navy;
      }
    }

    .modal-body {
      padding: 1rem 1.5rem !important;
    }

    .modal-footer {
      padding: 0rem 1.31rem 0.7rem;
      border-top: none;
      button {
        padding: 3px 27px;
      }
    }

    .modal_save_btn_style,
    .modal_cancel_btn_style {
      height: 28px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.44px;
      color: $white;
    }

    .modal_save_btn_style {
      background-color: $dark-green;
      border-color: $dark-green;

      &:hover,
      &:focus {
        background-color: $dark-green;
        border-color: $dark-green;
        box-shadow: none;
      }
    }

    .modal_cancel_btn_style {
      background-color: $dark-red;
      border-color: $dark-red;

      &:hover,
      &:focus {
        background-color: $dark-red;
        border-color: $dark-red;
        box-shadow: none;
      }
    }

    .modal_label_style {
      font-size: 12.5px;
      font-weight: bold;
      margin-bottom: 4px;
      color: $mid-black;
    }

    .plus_icon_btn_style,
    .delete_btn_style,
    .sucsses_btn_style {
      height: 22px;
      padding: 0px 4px;

      .MuiSvgIcon-root {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.modal-dialog-centered {
  min-width: 40vw;
}

.modal-backdrop {
  background-color: #8e959e;
}

.modal-backdrop.show {
  opacity: 0.32;
}
/*-------------------------------------------------- End of Modal style -----------------------------------------*/

/*------------------------------------------------ Start of Media Queries ---------------------------------------*/
@media only screen and (max-width: 512px) {
  // Start of modal style
  .modal {
    .modal-dialog {
      margin: 1.5rem;
    }

    .modal-content {
      div.modal-body {
        padding: 1rem 0.7rem !important;
      }

      div.modal-header {
        padding: 0.5rem 0.7rem !important;
      }

      div.modal-footer {
        padding: 0rem 0.5rem 0.7rem;
      }
    }
  }
  // End of modal style
}

@media only screen and (max-width: 767px) {
  // Start of general style
  .content {
    padding: 20px 17px;
  }

  .custom_nav_tabs_wrapper_style {
    .nav-link {
      padding: 4px 10px 2px;
    }
  }

  .add_new_btn_style {
    p {
      display: none;
    }
  }
  // End of general style

  // Start of navbar style
  .navbar {
    padding: 7px 18px;

    .logo_wrapper_style {
      img {
        height: 34px;
      }
    }
  }
  // End of navbar style
}
/*------------------------------------------------- End of Media Queries ---------------------------------------*/

/*------------------------------------------ End of General styles --------------------------------------------*/
