.card_wrapper_style {
  box-shadow: 0 3px 6px 0 #e9e8e8;
  background-color: $white;
  padding: 12px 22px 4px;
}

@media only screen and (max-width: 767px) {
  .card_wrapper_style {
    padding: 8px 13px 4px 2px;
  }
}
